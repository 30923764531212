import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_ORIGIN;

export const getProjectData = async (projectId: number, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/project/${projectId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getAllDataForSelf = async (accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/user/self`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getProjectRespondentData = async (projectId: number, respondentId: number, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/project/${projectId}/respondent/${respondentId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getRespondentData = async (respondentId: string, project: any, accessToken: any) => {
  const config = {
    url: `${apiServerUrl}/respondent/${project?.organization.tag}/${project?.tag}/${respondentId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getAllUsers = async ( accessToken: any ) => {

  const config = {
    url: `${apiServerUrl}/admin/user/all`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  };

};

export const getOrganizations = async ( accessToken: any ) => {

  const config = {
    url: `${apiServerUrl}/admin/organization/all`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  };

};

export const createOrganizationAndProject = async ( organizationName: string, projectName: string, projectType: string, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/project`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      organizationName: organizationName,
      projectName: projectName,
      projectType: projectType
    }
  }

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  }
};

export const createOrganization = async ( organizationName: string, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/organization`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      organizationName: organizationName
    }
  }

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  }
};

export const createProject = async ( organizationID: number, projectForm: any, accessToken: any ) => {
  let goalAttributes = '';
  
  projectForm.goal_attributes.forEach((element: any) => {
    goalAttributes += element.value + ", "
  });

  const config = {
    url: `${apiServerUrl}/project`,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      ...projectForm,
      project_type: projectForm.project_type,
      goal_attributes: goalAttributes,
      organizationId: organizationID,
    }
  }

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  }
};

export const updateProject = async ( projectId: number, projectForm: any, accessToken: any ) => {
  let goalAttributes = '';
  
  projectForm.goal_attributes.forEach((element: any) => {
    goalAttributes += element.value + ", "
  });

  const config = {
    url: `${apiServerUrl}/project/${projectId}`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      ...projectForm,
      project_type: projectForm.project_type,
      goal_attributes: goalAttributes
    }
  }

  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error
  }
};

export const deleteProject = async ( projectId: number, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/project/${projectId}`,
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
};

export const archiveProject = async ( projectId: number, accessToken: any ) => {
  const config = {
    url: `${apiServerUrl}/project/${projectId}/archive`,
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  }
};