import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import { PageLayout } from "./PageLayout";

export const SilentLogin = () => {
  const navigate = useNavigate();

  const { user, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && user) {
      loginWithRedirect({
        appState: {
          returnTo: "/dashboard",
        },
        authorizationParams: {
          prompt: "none",
        },
      });
    };

    if (user && user.email_verified) {
      return navigate("/");  
    }

    // return navigate("/", { state: { triedSilentLogin: true }});

  }, [ isLoading, user ]);

  return (
      <Loading />
  );
};
