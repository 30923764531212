import React from "react";

export const NotFoundPage = () => {
  return (
    <>

      <div className="hero">
        <p className="from-to">GutCheck is now</p>
        <img className="bl-logo" src={'/images/brightloop-yellow.svg'} />
        <h1>Bright<span className="bold">Loop</span></h1>
        <p className="">Click <a href="https://app.brightloop.ai">here</a> to go there now or wait 5 seconds to be redirected.</p>
        <p className="hidden">The app is momentarily unavailable as we make the transition.</p>
      </div>

      <div className="footer">
        
      </div>
    </>

  );
};
